<template>
  <div class="item-ecert">
        <div class="item-ecert-img">
            <el-link :underline="false" :href="'/ecert/detail/' + certData.id"><img :src="certData.picture" alt=""></el-link>
        </div>
        <div class="item-ecert-text">
            <p class="item-ecert-title"><el-link :underline="false" :href="'/ecert/detail/' + certData.id">{{certData.topic}}</el-link></p>
            <p class="item-ecert-decs">
                {{ $t('data.eCert.eCertTopic') }}: <el-link :underline="false" :href="'/ecert/detail/' + certData.id">{{certData.topic}}</el-link><br>
                {{ $t('data.eCert.createTime') }}: {{certData.created_at}}<br>
                {{ $t('data.eCert.completedApplication') }}: <el-link :underline="false" href="/ecert/approval">{{certData.completed_application}}</el-link><br>
                {{ $t('data.issuedCerts.table.status') }}: 
                <span v-if="certData.status === 1">{{ $t('data.status.valid') }}</span>
                <span v-else-if="certData.status === 2">{{ $t('data.status.expired') }}</span>
                <span v-else-if="certData.status === 3">{{ $t('data.status.cancel') }}</span>
                <span v-else-if="certData.status === 4">{{ $t('data.status.change') }}</span>
                <span v-else-if="certData.status === 5">{{ $t('data.status.amended') }}</span>
                <span v-else-if="certData.status === 6">{{ $t('data.status.remark') }}</span>
            </p>
            <div class="mt-10 btn-shrink">
                <el-button size="mini" round @click="pageJump('/ecert/edit/' + certData.id)">{{ $t('data.public.publish') }}</el-button>
                <el-button type="primary" size="mini" round @click="dialogVisible = true">{{ $t('data.public.share') }}</el-button>
                <el-tooltip class="item" effect="dark" placement="bottom">
                    <template #content>
                    　　{{ $t('data.eCert.duplicateDesc1') }} <br> {{ $t('data.eCert.duplicateDesc2') }}
                    </template>
                    <el-button type="danger" size="mini" round @click="copyCert()"  v-if="$store.getters.isManagerAuth">{{ $t('data.public.duplicate') }}</el-button>
                </el-tooltip>
            </div>
        </div>
    </div>

    <el-dialog
        :title="$t('data.eCert.requesteCertQRcode')"
        v-model="dialogVisible"
        width="40%">
        
        <div class="share-content">
            <p>{{ $t('data.eCert.scanQRCode') }}</p>
            <h3 class="mb-10">{{ $t('data.eCert.certTitle') }}: {{certData.topic}}</h3>
            <img :src="certData.qrcode">
            <p><el-button  type="primary" size="mini" @click="downloadQrcode(certData.download_qrcode)">{{ $t('data.eCert.printQRCode') }}</el-button></p>
            <p>{{ $t('data.eCert.copyLink') }}<el-button type="primary" size="mini" style="margin-left: 5px;" @click="copyLink(certData.app_link)">{{ $t('data.eCert.copyLinkBtn') }}</el-button></p>
            <p><el-link :underline="false" :href="certData.app_link">{{ certData.app_link }}</el-link></p>
        </div>

    </el-dialog>
</template>

<script>
import { ElMessage } from 'element-plus'
import { certsTemplateDuplicate } from '../../api/index'
export default {
    name: 'itemCert',
    emits: ['copyCert'],
    props:{
        certData: {
            type: Object,
            default: {name: 'K12畢業證書', id: 1}
        }
    },
    data() {
        return {
            dialogVisible: false
        };
    },
    mounted() {
    },
    methods: {
        copyLink(shareLink){
            var input = document.createElement("input");
            input.value = shareLink;
            document.body.appendChild(input);
            input.select();
            document.execCommand("Copy");
            document.body.removeChild(input);
            ElMessage.success({
                message: 'Copy Success',
                type: 'success'
            });
        },
        pageJump(url){
            this.$router.push(url);
        },
        copyCert(){
            let apiData = {
                id: this.certData.id
            }
            certsTemplateDuplicate({apiData}).then( res => {
                ElMessage.success({
                    message: 'Success',
                    type: 'success'
                });
                this.$emit('copyCert', this.certData);
            }) 
        },
        downloadQrcode(url){
            // window.location.href = '/ecert/qrcode-print?template_id='+id
            window.open(url);
        }
    }
}
</script>

<style scoped>
.item-ecert{
    margin-bottom: 30px;
}
.item-ecert-img img{
    width: 100%;
    height: auto;
    object-fit: cover;
}
.item-ecert-text{
    padding: 0px 15px;
}
.item-ecert-text .item-ecert-title{
    font-size: 16px;
    font-weight: 600;
}
.item-ecert-text .item-ecert-decs{
    font-size: 13px;
    color: #999;
}
.share-content{
    text-align: center;
}
.share-content p{
    margin-bottom: 10px;
}
.share-content img{
    width: 170px;
    height: auto;
    margin-bottom: 10px;
}
.btn-shrink .el-button{
    margin-left: 0px;
    margin-bottom: 10px;
    margin-right: 10px;
}
</style>
