<template>
  <div class="cert-list">
    <div class="page-header">
        <el-row>
            <el-col :span="12">
                <div class="header-desc">
                    <p class="mb-10">
                        {{ $t('data.eCert.branchName') }}:
                        <el-select v-model="branchId" placeholder="Branch" size="mini" @change="branchChange" :disabled="!this.$store.getters.isAdminAuth">
                            <el-option
                            v-for="item in branchList"
                            :key="item.id"
                            :label="item.name"
                            :value="item.id"
                            >
                            </el-option>
                        </el-select>
                    </p>
                    <p class="mb-10">{{ $t('data.eCert.remainingIssues') }}: {{remainingIssues}}</p>
                </div>
            </el-col>
            <el-col :span="12" align="right" v-if="this.$store.getters.isManagerAuth">
                <router-link to='/ecert/edit'><el-button icon="el-icon-tickets" type="primary" round>{{ $t('data.header.createNewEcert') }}</el-button></router-link>
            </el-col>
        </el-row>
    </div>

    <el-row :gutter="20">
        <el-col :span="24">
            <el-card shadow="always">
                <el-collapse v-model="activeName" accordion>
                    <el-collapse-item v-for="(item, index) in templateList" :key="index" :title="item.name" :name="index" >
                        <el-row :gutter="20">
                            <el-col :span="25" v-for="(item_certs, sub_index) in item.template_certs" :key="sub_index">
                                <item-cert @copyCert='addCert' :certData="item_certs"></item-cert>
                            </el-col>
                        </el-row>
                    </el-collapse-item>
                </el-collapse>
            </el-card>
        </el-col>
    </el-row>
  </div>
</template>

<script>
import itemCert from "../../components/itemCert/ItemCert"
import { templateCertsList,branchesList } from '../../api/index'
export default {
    name: 'cert_list',
    components: {
        itemCert
    },
    data() {
        return {
            activeName: 0,
            certList:[{id: 1,name: 'K12畢業證書'}],
            templateList: [],
            branchId: null,
            options: [{
                value: 'Branch',
                label: 'Branch',
            },{
                value: 'Branch2',
                label: 'Branch2',
            }],
            branchList: [],
            remainingIssues: null
        };
    },
    mounted() {
        this.initPageData();
        this.getBranchInfo();
    },
    methods:{
        initPageData(){
            let apiData = {}
            templateCertsList({apiData}).then( res => {
              this.templateList = res.data;
            });
            branchesList({}).then( res => {
                this.branchList = res.data;
                this.branchChange();
            })
        },
        getBranchInfo(){
            let userInfo = JSON.parse(localStorage.getItem("userInfo"));
            if(userInfo){
                this.branchId = userInfo.info.branch.id;
            }
        },
        branchChange(){
            this.remainingIssues = this.branchList.filter(item => item.id === this.branchId)[0].can_issue_limit;
        },
        addCert(val){
            this.initPageData();
        }
    }
}
</script>

<style>
.el-collapse-item__header{
    font-size: 18px;
    font-weight: 600;
}

</style>
